.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}


.e-grid-menu .e-reload-01:before {
  content: "\e82c";
}

.e-grid .e-copy::before,
.e-grid-menu .e-copy::before {
  content: "\e70a";
}

.e-grid .e-copy-1::before,
.e-grid-menu .e-copy-1::before {
  content: "\e33d";
}

.e-grid .e-file-new::before,
.e-grid-menu .e-file-new::before {
  content: "\e712";
}

.e-grid .e-search-1::before,
.e-grid-menu .e-search-1::before {
  content: "\e993";
}

.e-grid .e-research::before,
.e-grid-menu .e-research::before {
  content: "\e99d";
}

.e-grid .e-undo::before,
.e-grid-menu .e-undo::before {
  content: "\e341";
}

.e-grid .e-redo::before,
.e-grid-menu .e-redo::before {
  content: "\e354";
}

.e-grid .e-bookmark::before,
.e-grid-menu .e-bookmark::before {
  content: "\ec0c";
}

.e-grid .e-send-1::before,
.e-grid-menu .e-send-1::before {
  content: "\e816";
}

.e-icons .e-history,
.e-grid .e-history::before,
.e-grid-menu .e-history::before {
  content: "\e203";
}

.e-grid .e-check::before,
.e-grid-menu .e-check::before {
  content: "\e7ff";
}

.e-grid .e-lock::before,
.e-grid-menu .e-lock::before {
  content: "\eb3a";
}

.e-grid .e-repeat::before,
.e-grid-menu .e-repeat::before {
  content: "\e308";
}
.e-grid .e-changes-track::before,
.e-grid-menu .e-changes-track::before {
  content: "\e20a";
}

.e-grid .e-changes-reject::before,
.e-grid-menu .e-changes-reject::before {
  content: "\e204";
}

.e-grid .e-changes-next::before,
.e-grid-menu .e-changes-next::before {
  content: "\e209";
}

.e-grid .e-changes-previous::before,
.e-grid-menu .e-changes-previous::before {
  content: "\e205";
}
.e-grid .e-table-update::before,
.e-grid-menu .e-table-update::before {
  content: "\e19e";
}

.e-grid .e-display-for-review::before,
.e-grid-menu .e-display-for-review::before {
  content: "\e203";
}

.e-grid .e-changes-accept::before,
.e-grid-menu .e-changes-accept::before {
  content: "\e19f";
}

.e-grid .e-changes-previous::before,
.e-grid-menu .e-changes-previous::before {
  content: "\e205";
}

.e-grid .e-show-markup::before,
.e-grid-menu .e-show-markup::before {
  content: "\e206";
}

.e-grid .e-changes-next::before,
.e-grid-menu .e-changes-next::before {
  content: "\e209";
}

.e-grid .e-comment-reopen::before,
.e-grid-menu .e-comment-reopen::before {
  content: "\e818";
}

.e-grid .e-password::before,
.e-grid-menu .e-password::before {
  content: "\e290";
}
/*  content: "\e91a";*/

.e-grid .e-print-layout::before,
.e-grid-menu .e-print-layout::before {
  content: "\e194";
}

.e-grid .e-text-form::before,
.e-grid-menu .e-text-form::before {
  content: "\e198";
}

.e-grid .e-copy2::before,
.e-grid-menu .e-copy2::before {
  content: "\e280";
}

.e-grid .e-columnchooserdiv::before,
.e-grid-menu .e-columnchooserdiv::before {
  content: "\e714";
}

.e-grid .e-zoom::before,
.e-grid-menu .e-zoom::before {
  content: "\e19f";
}

.e-grid .e-wordexport::before,
.e-grid-menu .e-wordexport::before {
  content: "\e7b0";
}


