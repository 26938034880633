@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import "../node_modules/@fontsource/roboto/400.css";
@import "../node_modules/@fontsource/roboto/500.css";
@import "../node_modules/@fontsource/material-icons/index.css";
@import "../node_modules/@fontsource-variable/material-symbols-rounded/index.css";

@import "../node_modules/@syncfusion/ej2-base/styles/material3.css";
@import "../node_modules/@syncfusion/ej2-angular-layouts/styles/material3.css";
@import "../node_modules/@syncfusion/ej2-icons/styles/material3.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material3.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material3.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material3.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material3.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material3.css";
@import '../node_modules/@syncfusion/ej2-lists/styles/material3.css';
@import "../node_modules/@syncfusion/ej2-navigations/styles/material3.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material3.css";
@import "../node_modules/@syncfusion/ej2-angular-grids/styles/material3.css";
@import "../node_modules/@syncfusion/ej2-pdfviewer/styles/material3.css";
@import "../node_modules/@syncfusion/ej2-notifications/styles/material3.css";
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material3.css';

@import "styles-icons.css";
@import "styles-icons-org.css";
@import "styles-dawa.css";

/*$primary: #6200ee;*/

:root {
  --color-sf-black: 0, 0, 0;
  --color-sf-white: 255, 255, 255;
  --color-sf-primary: 98, 0, 238;
  --color-sf-primary-container: 224, 224, 224 /*234, 221, 255*/;
  --color-sf-secondary: 98, 91, 113;
  --color-sf-secondary-container: 232, 232, 240;
  --color-sf-tertiary: 125, 82, 96;
  --color-sf-tertiary-container: 255, 216, 228;
  --color-sf-surface: 255, 255, 255;
  --color-sf-surface-variant: 231, 224, 236;
  --color-sf-background: var(--color-sf-surface);
  --color-sf-on-primary: 255, 255, 255;
  --color-sf-on-primary-container: 33, 0, 94;
  --color-sf-on-secondary: 255, 255, 255;
  --color-sf-on-secondary-container: 30, 25, 43;
  --color-sf-on-tertiary: 255, 255, 255;

  --mat-toolbar-standard-height: 48px;

  --mat-form-field-container-text-size: 14px;
  --mdc-dialog-supporting-text-size: 12px;
  --mdc-dialog-field-container-text-size: 14px;
  --mat-form-field-subscript-text-line-height: 8px
}

.e-dark-mode {
  --color-sf-black: 0, 0, 0;
  --color-sf-white: 255, 255, 255;
  --color-sf-primary: 208, 188, 255;
  --color-sf-primary-container: 79, 55, 139;
  --color-sf-secondary: 204, 194, 220;
  --color-sf-secondary-container: 74, 68, 88;
  --color-sf-tertiary: 239, 184, 200;
  --color-sf-tertiary-container: 99, 59, 72;
  --color-sf-surface: 28, 27, 31;
  --color-sf-surface-variant: 28, 27, 31;
  --color-sf-background: var(--color-sf-surface);
  --color-sf-on-primary: 55, 30, 115;
  --color-sf-on-primary-container: 234, 221, 255;
  --color-sf-on-secondary: 51, 45, 65;
  --color-sf-on-secondary-container: 232, 222, 248;
  --color-sf-on-tertiary: 73, 37, 50;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: Roboto, sans-serif;
}

pre {
  font-family: Roboto, sans-serif;
}

.mat-mdc-form-field-infix {
  padding-bottom: 8px !important;
}

div.cer-secondary {
  background-color: rgba(var(--color-sf-secondary-container), 1);
}

section.cer-secondary {
  background-color: rgba(var(--color-sf-secondary-container), 1);
}

div.indexSplash > img {
  display: block;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
}

.full-height {
  height: 100%;
}

.overflow-auto {
  overflow: auto;
}

.e-dialog .e-dlg-content.dialog-text.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-100 {
  height: 100%;
  width: 100%;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-fixed {
  flex-grow: 0;
  flex-shrink: 0;
}

.flex-limit-height {
  min-height: 0;
}

.flex-limit-width {
  min-width: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink {
  flex-shrink: 1;
}


.fullSize {
  height: 100%;
  width: 100%;
}

.e-splitter  .e-navigate-arrow {
  z-index: 99999;
}

.mat-drawer-inner-container {
  overflow: hidden !important;
}

.mat-sidenav-content {
    overflow: hidden !important;
}

.mat-dialog-container {
  overflow: hidden !important;
}

.mat-mdc-tab-body-wrapper {
  flex-grow: 1;
}

.mat-tab-body-wrapper{
  flex-grow: 1;
  width: 100%;
  overflow: auto !important;
}

div.dialogForm {
  z-index: 0;
  height: 100%;
}

div.e-pane {
  overflow: hidden !important;
}

.mat-mdc-form-field {
  flex-grow: 1;
  flex-shrink: 1;
}

.mat-form-field-flex > .mat-form-field-infix {
  width: auto;
}

.mat-mdc-form-field-flex > .mat-mdc-form-field-infix {
  width: auto;
}

div > .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #ffffff !important;
    border-radius: 0 0 0 0;
    padding: 0 0 0 0;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
  }

.e-toolbar-text {
  opacity: 1 !important;
}

.e-toolbar-text > button {
  background-color: #3f51b5 !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.e-toolbar-text>button>span {
  color: #ffffff !important;
}

.e-grid td.e-active {
  background: rgba(var(--color-sf-primary-container), 1);
  color: rgba(var(--color-sf-on-surface-variant));
}

.e-search-small {
  width: 120px !important;
}

.avatar {
  filter: grayscale(100%);
}

[mat-dialog-title] {
/*  margin: -24px -24px 0px -24px !important;*/
  padding: 10px 24px; 
  cursor: move;
}

.resizable-mat-dialog-panel { 
  resize: both;
  overflow: auto;
  max-width: unset !important;
  max-height: unset !important;
  position: absolute !important;
}

.e-grid .e-gridheader {
  padding-right: 2px;
}

.e-grid .e-gridheader thead .e-icons:not(.e-check):not(.e-stop) {
  font-size: 13px;
}

.e-grid .e-gridheader .e-sortfilter .e-headercelldiv, .e-grid .e-gridheader .e-stackedheadercelldiv {
  padding-right: 14px !important;
  margin-right: 14px !important;
}

.e-grid .e-gridheader .e-sortfilter .e-headercelldiv {
  padding-right: 14px !important;
  margin-right: 10px !important;
}

.e-grid .e-sortfilterdiv {
  padding-right: 14px !important;
  padding-top: 4px !important;
  margin-right: 8px !important;
}

.e-grid .e-filtermenudiv {
  padding-right: 0px !important;
  padding-top: 4px !important;
}

.e-grid .e-headercelldiv {
  font-weight: 400;
}

.e-grid .e-focused:not(.e-menu-item):not(.e-editedbatchcell) {
  box-shadow: 0 0 0 1px rgba(var(--color-sf-secondary)) inset;
}

.e-grid :focus:not(input) {
  box-shadow: 0 0 0 2px rgba(var(--color-sf-primary)) inset !important;
}

.e-grid-form-field-group {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
}

.e-grid-form-field-group table {
  width: 100%;
}

.cer-disabled {
  cursor: not-allowed;
}

.e-checkbox-wrapper {
  padding: 1px 0px;
}

.e-rowcell > .e-checkbox-wrapper {
    padding: 1px 0px;
}  

.e-gridcontent .e-content {
  scrollbar-width: thin;
}

.e-grid:not(.e-row-responsive) .e-gridcontent tr.e-row:first-child td.e-cellselectionbackground,
.e-grid td.e-cellselectionbackground {
  border: 1px none rgba(var(--color-sf-on-surface));
}

div.e-grid-field-group {
  flex-grow: 1;
  align-items: flex-start;
  padding: 0 8px;
}

.e-richtexteditor.e-rte-full-screen {
  position: absolute;
}

h3.e-grid-field-group-header-more {
  padding-top: 20px;
}


tr.e-row.e-altrow {
  background-color: #f7f7ff;
}

.e-dropdownbase .e-list-item {
  padding-right: 0px;
}

ul.e-list-parent.ul {
  display: table;
}

ul.e-list-parent.ul > li.e-list-item {
  display: table-row;
}

ul.e-list-parent.ul > li.e-list-item > span.ddl-item{
  display: table-cell;
}

span.e-dll-header {
  padding-right: 24px;
}

span.ddl-header {
  display: table;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
}

span.ddl-header-col {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
  text-indent: 16px;
}

span.ddl-item {
  display: table;
  margin: auto;
  width: 100%;
}

span.ddl-col {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
  text-indent: 16px;
}

.dialog-field {
  padding: 6px;
}

.e-chip-text {
  font-size: 14px;
  font-weight: 400;
  overflow: visible;
}

.e-rowcell:has(> .e-chip-list) {
  padding: 0px !important;
}

.e-rowcell .e-chip-list .e-chip.e-chip-small {
  border: 1px solid;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 300;
  height: 20px;
  line-height: 14px;
  margin: 0px;
  padding: 0 2px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.e-rowcell .e-chip-list .e-chip.e-chip-small .e-chip-text  {
  font-size: 10px;
  font-weight: 300;
  overflow: visible;
}

.e-rowcell .e-chip-list .e-chip .e-chip-text  {
  font-size: 12px;
  font-weight: 400;
  overflow: visible;
}

.e-chip-list.e-chip, 
.e-chip-list .e-chip {
  justify-content: flex-start;
  border: 0px solid;
}

/* Splitter */
.splitter.e-splitter .e-split-bar.e-split-bar-vertical.e-resizable-split-bar::after {
  content: "";
  cursor: row-resize;
  display: block;
  height: 16px;
  position: absolute;
  width: 100%;
  z-index: 12;
  margin-top: 20px;
}

.splitter.e-splitter .e-split-bar.e-split-bar-horizontal.e-resizable-split-bar::after {
  content: "";
  cursor: col-resize;
  display: block;
  height: 100%;
  position: absolute;
  width: 16px;
  z-index: 10;
  margin-left: 20px;
}

/* Chat */

.e-grid .e-rowcell.e-templatecell.chat-link-cell {
  padding-bottom: 0px;
  padding-top: 0px;
  vertical-align: bottom;
}

.chat-link-active > .mat-icon > .mat-badge-content {
  width: 18px;
  height: 18px;
  right: -2px;
  line-height: 18px;
  font-weight: 300;
  font-size: 11px;
  border: 1px solid #ffffff;
}

.chat-link-active > .mat-icon {
  transform: scale(0.9);
  vertical-align: bottom;
}

.chat-link-none > .mat-icon {
  filter: grayscale(100%);
  margin-bottom: 5px;
  vertical-align: bottom;
}

.chat-link-unknown > .mat-icon {
  transform: scale(0.8);
  filter: grayscale(100%) blur(1px);
  margin-bottom: 5px;
  vertical-align: bottom;
}

.numeric-align .e-numerictextbox{ 
  text-align: right; 
} 


.e-input-group .e-input-group-icon,
.e-input-group.e-control-wrapper .e-input-group-icon {
  -ms-flex-align: center;
      align-items: center;
  border: 0 solid;
  border-width: 0;
  box-sizing: content-box;
  cursor: pointer;
  -ms-flex-direction: column;
      flex-direction: column;
  font-size: 16px;
  -ms-flex-pack: center;
      justify-content: center;
  line-height: 1;
  min-height: 18px;
  min-width: 18px;
  padding: 0;
  text-align: center;
  border-radius: 8px;
}

e-input-group .e-clear-icon, .e-input-group.e-control-wrapper .e-clear-icon {
  min-height: 18px;
  min-width: 18px;
  padding: 0px;
  margin: 0px;
  border-radius: 8px;
}
